import BotMart from '../pages-dynamic/botmart';
import CopSupply from '../pages-dynamic/copsupply';
import axios from 'axios';
import React, { useEffect } from 'react';
import styled from 'styled-components';
// import Features from '../components/landing/features';
import Hero from '../components/landing/hero';
import TrendingReleases from '../components/landing/trending-releases';
import { analytics } from '../lib/analytics';
import { motion } from 'framer-motion';

const LandingWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 972px) {
    overflow: hidden;
  }
`;

const Landing = (props) => {
  useEffect(() => {
    analytics.page('Home');
  }, []);
  return (
    <LandingWrapper>
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Hero trendingBots={props.trendingBots} />
        <TrendingReleases upcomingReleases={props.upcomingReleases} />
      </motion.div>
      {/* <Features /> */}
    </LandingWrapper>
  );
};

let app;

switch (process.env.WHITE_LABEL_ORG) {
  case 'botmart':
    app = BotMart;
    break;
  case 'copsupply':
    app = CopSupply;
    break;
  default:
    app = Landing;
    break;
}
export default app;

export async function getStaticProps() {
  if ([].includes(process.env.WHITE_LABEL_ORG)) {
    return {
      props: {},
    };
  }

  const upcomingReleases = (
    await axios.get(
      `${process.env.BACKEND_URI}/releases/upcoming?category=sneakers&limit=3`,
    )
  ).data;
  // const trendings = (
  //   await axios.get(
  //     `${process.env.BACKEND_URI}/releases/trending?category=sneakers&limit=3&page=2`,
  //   )
  // ).data;
  const trendingBots = (
    await axios.get(`${process.env.BACKEND_URI}/bots/trending?limit=3`)
  ).data;

  return {
    props: {
      upcomingReleases,
      // trendings,
      trendingBots,
    },
  };
}
