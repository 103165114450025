import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import TrendingReleaseCard from './TrendingReleaseCard';

const TrendingReleasesWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  @media only screen and (max-width: 450px) {
    margin-top: 60px;
  }
  & > h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
  }
`;

const TrendingReleasesGrid = styled.div`
  margin-top: 32px;
  display: grid;
  grid-gap: 34px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  @media only screen and (max-width: 450px) {
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const TrendingReleases = ({ upcomingReleases }) => {
  return (
    <TrendingReleasesWrapper id="trendingReleases">
      <h2>Upcoming Releases</h2>
      <TrendingReleasesGrid>
        {upcomingReleases.map((product, key) => {
          return (
            <motion.div
              key={key}
              initial={{ y: 200 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                height: '100%',
              }}
            >
              <TrendingReleaseCard
                image={product.media.imageUrl}
                resellPrice={product.market.lastSale ?? 'N/A'}
                title={product.title}
                date={product.releaseDate}
                retailPrice={product.retailPrice}
              />
            </motion.div>
          );
        })}
      </TrendingReleasesGrid>
    </TrendingReleasesWrapper>
  );
};

export default TrendingReleases;
